// @flow

import classNames from 'classnames';
import React, { Fragment } from 'react';
import type { Node } from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';
import { Row, Col } from '@riseart/antd-provider';
import { CoverMedia, Heading, Paragraph, Button, MediaQuery } from '@riseart/common';
import { Section, Wrapper } from '@riseart/layout';
import { Picture } from 'shared_components/common/artdirection/picture/Picture';
import { generateContentUrl } from 'shared_services/riseart/utils/Utils';
import { ShortcutLinks } from 'shared_components/common/links/Shortcut';

import { raScreenXsMax, raScreenSm } from '@riseart/antd-provider/dist/website/variables.less';
import {
  pageBannerCls,
  pageBannerSectionCls,
  pageBannerSectionDarkCls,
  pageBannerSectionWhiteCls,
  pageBannerContentCls,
  pageBannerTextCls,
  pageBannerMediaCls,
  pageBannerBottomCls,
  pageBannerFooterCls,
  pageBannerLazyloadCls,
} from './PageBanner.less';

type Props = {
  className?: string,
  title?: string,
  secondaryTitle?: string,
  text?: string,
  media: {
    type?: string,
    sources: Array<any>,
  },
  button: {
    text?: string,
    link?: string,
    external?: boolean,
    type?: string,
    size?: string,
  },
  footerTitle?: string,
  buttons?: Array<{
    external: boolean,
    target?: string,
    text: string,
    href: string,
  }>,
  background?: string,
};

/**
 * CmsModulePageBanner
 *
 * @param {Props} props
 * @returns {Node}
 */
export const CmsModulePageBanner = ({
  title,
  secondaryTitle,
  text,
  media,
  button,
  footerTitle,
  buttons,
  background = 'light',
  className,
}: Props): Node => {
  const BACKGROUND_TO_SETTINGS = {
    dark: {
      className: pageBannerSectionDarkCls,
      buttonType: 'ghost',
    },
    light: {
      className: null,
      buttonType: 'secondary',
    },
    white: {
      className: pageBannerSectionWhiteCls,
      buttonType: 'secondary',
    },
  };
  const SETTINGS = BACKGROUND_TO_SETTINGS[background] || BACKGROUND_TO_SETTINGS.light;
  const buttonSection =
    button || footerTitle || buttons ? (
      <div className={pageBannerBottomCls}>
        {button ? (
          <Button
            isAnchor
            external={typeof button.external === 'boolean' ? button.external : true}
            type={SETTINGS.buttonType}
            size={button.size || 'medium'}
            title={button.text}
            href={button.link}
          >
            {button.text}
          </Button>
        ) : null}
        {footerTitle || (buttons && buttons.length) ? (
          <div className={pageBannerFooterCls}>
            {footerTitle ? (
              <Heading tag="h4" level="4">
                {footerTitle}
              </Heading>
            ) : null}
            <ShortcutLinks links={buttons} />
          </div>
        ) : null}
      </div>
    ) : null;

  return (
    <Section
      {...(!background ? { background: 'gray' } : null)}
      padding="none"
      className={classNames(pageBannerSectionCls, SETTINGS.className, className)}
    >
      <Wrapper className={pageBannerCls} noPadding sizes="wide">
        <Row type="flex" align="end" justify="end" className={pageBannerContentCls}>
          <Col xs={24} sm={8} className={pageBannerTextCls}>
            {secondaryTitle ? (
              <Heading tag="h3" level="3">
                {secondaryTitle}
              </Heading>
            ) : null}
            <Heading tag="h2" level="2">
              {title}
            </Heading>
            <Paragraph>{text}</Paragraph>
            <MediaQuery minWidth={raScreenSm}>{buttonSection}</MediaQuery>
          </Col>
          {media ? (
            <Col xs={24} sm={12}>
              <LazyLoad height={100} className={pageBannerLazyloadCls}>
                <MediaQuery minWidth={raScreenSm}>
                  {(isLargeScreen) => {
                    const WrapperComponent = isLargeScreen ? CoverMedia : Fragment;

                    return (
                      <WrapperComponent>
                        {media.type === 'image' && get(media.sources, '[0]') ? (
                          <Picture
                            type="banner.page"
                            artDirectionKey="cms.pageBanner"
                            image={get(media.sources, '[0]')}
                            alt={title}
                            className={pageBannerMediaCls}
                          />
                        ) : null}
                        {media.type === 'video' ? (
                          <video autoPlay loop muted playsInline className={pageBannerMediaCls}>
                            {media.sources.map(({ id, key, extension, mimeType }) => (
                              <source
                                key={id}
                                src={generateContentUrl(key, extension)}
                                type={mimeType}
                              />
                            ))}
                            <FormattedMessage id="components.media.noVIdeoSupport" />
                          </video>
                        ) : null}
                      </WrapperComponent>
                    );
                  }}
                </MediaQuery>
              </LazyLoad>
              <MediaQuery maxWidth={raScreenXsMax}>{buttonSection}</MediaQuery>
            </Col>
          ) : null}
        </Row>
      </Wrapper>
    </Section>
  );
};
